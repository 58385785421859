<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      agents: [],
      accounts: [],
      agent: { search: null },
      selectedAccounts: null,
      levelThreeAccountId: null,
      levelThreeAccounts: [],
      branchItem: {
        isNew: 1,
        item: {},
        code: null,
        account: null,
        Adaccount: null,
      },
      addExpense: [{}],
    };
  },
  methods: {
    selectedAgent(search) {
      this.http
        .post("agents/search", {
          search,
        })
        .then((res) => {
          if (res.status) {
            this.agents = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + " " + a.name
                : a.code + " " + a.name_en;
            });
          }
        });
    },
    selectedAccount(search) {
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
          search,
          type: "Expense",
          level: 3,
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
          this.search = "";
        });
    },
    createPo() {
      this.http
        .post("purchases/purchase", {
          expenses: this.addExpense,
          agent: this.agent.search,
        })
        .then((res) => {
          if (res.status) {
            this.addExpense = [{}];
            this.$router.push("/payments_invoices");
          }
        });
    },
    deleteOption(index) {
      this.addExpense.splice(index, 1);
    },
  },
  computed: {
    filteredAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
    filteredAgents() {
      return this.agents.filter((account) => {
        return account;
      });
    },
  },
  created() {},
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/payments_invoices`">
            <h4 class="m-0">{{ $t("payments.title") }}</h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>{{ $t("payments.new_payment") }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6"></div>
              <div
                class="col-6"
                style="display: flex; justify-content: flex-end"
              ></div>
            </div>
            <div class="row">
              <div class="col">
                <label for="asset_id">{{ $t("purchases.supplier") }}</label
                ><br />
                <AutoComplete
                  @keyup="selectedAgent(agent.search)"
                  v-model="agent.search"
                  :suggestions="filteredAgents"
                  @complete="agent.search = $event.query"
                  style="color: #333 !important; border-radius: 10px !important"
                />
              </div>
              <div class="col"></div>
            </div>
            <div>
              <div class="purchase-container">
                <div class="row mb-2">
                  <div class="col-10">
                    <label for="asset_id">{{
                      $t("purchases.choose_items")
                    }}</label>
                  </div>
                  <div class="col-2">
                    <button
                      type="button"
                      @click="addExpense.push({})"
                      class="btn"
                    >
                      <span
                        class="bx bxs-plus-circle fa-2x"
                        style="color: #2a3042 !important"
                      ></span>
                    </button>
                  </div>
                </div>
                <div class="mt-3">
                  <div v-for="(expense, index) in addExpense" :key="expense">
                    <div class="row mb-2">
                      <div class="col">
                        <AutoComplete
                          @keyup="selectedAccount(expense.search)"
                          v-model="expense.search"
                          :suggestions="filteredAccounts"
                          @complete="expense.search = $event.query"
                          style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
                      </div>
                      <div class="col d-flex">
                        <input
                          required
                          type="number"
                          style="margin-inline-end: 4px; display: inline"
                          v-model="expense.qty"
                          class="form-control"
                          :placeholder="$t('emps.qty')"
                        />
                        <input
                          required
                          type="number"
                          style="display: inline"
                          v-model="expense.unit_price"
                          class="form-control"
                          :placeholder="$t('emps.unit_price')"
                        />
                      </div>
                      <div class="col">
                        <button
                          class="btn btn-sm btn-danger"
                          @click="deleteOption(index)"
                          :disabled="addExpense.length == 1"
                        >
                          <i class="bx bx-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="purchases-footer float-end"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <router-link to="/payments_invoices">
                <button
                  class="btn btn-secondary"
                  style="margin-inline-end: 4px"
                >
                  {{ $t("popups.cancel") }}
                </button>
              </router-link>
              <button class="btn btn-primary" @click="createPo">
                {{ $t("payments.create_order") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.purchase-container {
  background: #fafafa;
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
.purchases-footer {
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
